@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;700&display=swap);
@import url(https://unpkg.com/leaflet@1.7.1/dist/leaflet.css);
html {
    font-size: 10px;
}

body {
    margin: 0;
    font-family: "Raleway", "Roboto", "Helvetica", "Arial", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
    width: 100%;
    height: 300px;
}

html {
  scroll-behavior: smooth;
}

.pin {
    margin: -35px 0 0 -20px;
    display: flex;
    align-items: flex-start;
    width: 220px;
    color: #C2B052;
}

@media screen and (max-width: 768px) {
    .pin {
        width: 150px;
    }
}

.pin-icon {
    font-size: 4rem;
}

.pin-text {
    margin: 10px 0 0 0;
    font-size: 1.8rem;
    font-weight: bold;
    color: #827537;
    background-color: rgb(248 249 250);
    border: 1px solid #cdcdcd;
    font-family: "Raleway", "Roboto", "Helvetica", "Arial", sans-serif;
}

