.pin {
    margin: -35px 0 0 -20px;
    display: flex;
    align-items: flex-start;
    width: 220px;
    color: #C2B052;
}

@media screen and (max-width: 768px) {
    .pin {
        width: 150px;
    }
}

.pin-icon {
    font-size: 4rem;
}

.pin-text {
    margin: 10px 0 0 0;
    font-size: 1.8rem;
    font-weight: bold;
    color: #827537;
    background-color: rgb(248 249 250);
    border: 1px solid #cdcdcd;
    font-family: "Raleway", "Roboto", "Helvetica", "Arial", sans-serif;
}
