@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;700&display=swap');
@import url('https://unpkg.com/leaflet@1.7.1/dist/leaflet.css');

html {
    font-size: 10px;
}

body {
    margin: 0;
    font-family: "Raleway", "Roboto", "Helvetica", "Arial", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
    width: 100%;
    height: 300px;
}
